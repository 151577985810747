.navbar{
    display: flex;
    justify-content: center;
    margin-bottom: 3em;
}
.navbar img {
    width: 400px;
    opacity: 0.8;
}

.search-bar {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .search-bar input[type="text"] {
    background-color: rgba(255,255,255,0.2);
    color: rgba(255,255,255,0.8);
    width: 600px;
    height: 44px;
    padding: 3px 20px;
    font-size: 16px;
    border: none;
    border-radius: 24px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  }
  .search-bar button {
    border: none;
    background:none;
    filter: invert();
    scale: 1.25;
    margin-left: 1em;
    padding-top: 0.3em;
    opacity: 0.65;
  }