body{
    background: linear-gradient(109.6deg, rgb(20, 30, 48) 11.2%, rgb(36, 59, 85) 91.1%);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Josefin Sans', sans-serif;
    width: 100vw;
    margin: 0;
    padding: 0;
}
.App{
    padding: 3em 1.5em;
}